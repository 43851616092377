/* eslint-disable office-addins/load-object-before-read */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
import { ERRORS, FILE_CHANGE_STATUS, LOCAL_STORAGE, MS_WORD_PARAMS, PLAT_FORM } from "./constants";
import {
  IAcceptAllTheTrackedChangesByUserName,
  IAcceptTrackedChangesInSelectionByUserName,
  IAddCommentBySelection,
  IFindWordInDocument,
  IGetPageContext,
  IHighlightAllOccurrenceOfText,
  // IHighlightText,
  IHighlightTextWithDuration,
  IInsertAfterCursor,
  IMoveChangesWithTrackOn,
  IRejectAllCommentsByUserName,
  IRejectAllTheTrackedChangesByUserName,
  IRejectTrackedChangesInSelectionByUserName,
  // IReplaceAllInstances,
  IReplaceSelection,
  IResolveAllCommentsByUserName,
  IResolveCommentInSelectionByUserName,
  IScrollToParagraph,
  ISetTrackChanges,
} from "./interfaces/AIBInterfaces";
import { findDifferences, markdownToHtml, printUtilErrorOnConsole } from "./utils";

export const getSelectedText = async () => {
  let result = "";
  try {
    await Word.run(async (context) => {
      const selection = context.document.getSelection();
      selection.load(MS_WORD_PARAMS.TEXT);
      await context.sync();
      result = selection.text;
    });
  } catch (error) {
    printUtilErrorOnConsole("getSelectedText", error);
  }
  return result;
};
export const highlightTextWithDuration = async (data: IHighlightTextWithDuration) => {
  let result;
  const { text, duration, highlightColor } = data;
  try {
    await Word.run(async (context) => {
      const body = context.document.body;
      const searchResults = body.search(text, { matchCase: false, matchWholeWord: true });
      context.load(searchResults, MS_WORD_PARAMS.ITEMS);
      await context.sync();
      if (searchResults.items.length > 0) {
        const range = searchResults.items[0];
        range.font.highlightColor = highlightColor;
        await context.sync();
        setTimeout(async () => {
          try {
            await Word.run(async (innerContext) => {
              const innerBody = innerContext.document.body;
              const innerSearchResults = innerBody.search(text, { matchCase: false, matchWholeWord: true });
              innerContext.load(innerSearchResults, MS_WORD_PARAMS.ITEMS);
              await innerContext.sync();
              if (innerSearchResults.items.length > 0) {
                const innerRange = innerSearchResults.items[0];
                innerRange.font.highlightColor = null;
                await innerContext.sync();
              }
            });
            result = true;
          } catch (innerError) {
            result = false;
            console.error("Error unhighlighting the word:", innerError);
          }
        }, duration);
      } else {
        result = false;
        console.log(`The word "${text}" was not found in the document.`);
      }
    });
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("highlightTextWithDuration", error);
  }
  return result;
};
export const findWordInDocument = async (data: IFindWordInDocument) => {
  let result;
  try {
    // Ensure the Office context is available
    if (Office.context.document) {
      await Word.run(async (context) => {
        const body = context.document.body;
        const searchResults = body.search(data.text, {
          matchCase: true,
          matchWholeWord: true,
        });
        context.load(searchResults, MS_WORD_PARAMS.TEXT);
        await context.sync();
        if (searchResults.items.length > 0) {
          result = `Found ${searchResults.items.length} instance(s) of "${data.text}" in the document.`;
        } else {
          result = `"${data.text}" not found in the document.`;
        }
      });
    } else {
      result = false;
    }
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("findWordInDocument", error);
  }
  return result;
};
export const scrollToParagraph = async (data: IScrollToParagraph) => {
  console.log("scrolling to text:", data.text);
  try {
    await Word.run(async (context) => {
      const body = context.document.body;

      // Search for the text in the document
      const searchResults = body.search(data.text.slice(0, 200).trim(), { matchCase: true });
      context.load(searchResults, "text, items");
      await context.sync();

      if (searchResults.items.length > 0) {
        // Get the first occurrence and scroll to it
        const firstResult = searchResults.items[0];
        const paragraph = firstResult.paragraphs.getFirst();
        paragraph.select(); // Scroll to the paragraph
        await context.sync();
      } else {
        console.log(`Text "${data.text}" not found in the document.`);
      }
    });
  } catch (error) {
    console.error("Error scrolling to the paragraph:", error);
  }
};
export const highlightText = async (data: IHighlightTextWithDuration) => {
  console.log("data for the highlight Text with duration", data);
  let { text, duration, highlightColor } = data;
  highlightColor = highlightColor || `Yellow`; // Set default highlight color to yellow if not provided
  let result;
  let properText;
  try {
    await Word.run(async (context) => {
      const body = context.document.body;
      context.load(body, MS_WORD_PARAMS.TEXT);
      await context.sync();
      properText = findIdentifierFromDocument(text, body.text);
      let searchResults = [];
      for (let start = 0; start < properText.length; start += 200) {
        let end = Math.min(start + 200, properText.length);
        let searchText = properText.substring(start, end);
        console.log(searchText, `search text from ${start} to ${end}`);
        const results = body.search(searchText, { matchCase: true });
        context.load(results, "text");
        searchResults.push(results);
      }
      await context.sync();

      if (searchResults.length > 0) {
        // Scroll to the first occurrence before highlighting
        await scrollToParagraph({ text: text });

        // Highlight the text
        searchResults.forEach((results) => {
          results.items.forEach((item) => {
            const range = item.getRange(Word.RangeLocation.whole);
            range.font.highlightColor = highlightColor;
          });
        });
        await context.sync();
      }
    });

    if (duration) {
      // Check if duration is provided
      setTimeout(async () => {
        try {
          await Word.run(async (context) => {
            const body = context.document.body;
            let searchResults = [];
            for (let start = 0; start < properText.length; start += 200) {
              let end = Math.min(start + 200, properText.length);
              let searchText = properText.substring(start, end);
              const results = body.search(searchText, { matchCase: true });
              context.load(results, "text");
              searchResults.push(results);
            }
            await context.sync();
            searchResults.forEach((results) => {
              results.items.forEach((item) => {
                const range = item.getRange(Word.RangeLocation.whole);
                range.font.highlightColor = null; // Remove highlight
              });
            });
            await context.sync();
          });
          result = true;
        } catch (innerError) {
          result = false;
          console.error("Error unhighlighting the text:", innerError);
        }
      }, duration);
    } else {
      result = true;
    }
    result = true;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("highlightTextWithDuration", error);
  }
  return result;
};
export const highlightAllOccurrenceOfText = async (data: IHighlightAllOccurrenceOfText) => {
  let count = 0;
  try {
    Word.run(async (context) => {
      const body = context.document.body;
      const searchResults = body.search(data.text, { matchCase: false });
      context.load(searchResults, MS_WORD_PARAMS.TEXT);
      await context.sync();
      searchResults.items.forEach((item) => {
        const range = item.getRange(Word.RangeLocation.whole);
        range.font.highlightColor = data.highlightColor;
        count++;
      });
      await context.sync();
    });
  } catch (error) {
    printUtilErrorOnConsole("highlightAllOccurrenceOfText", error);
  }
  return count;
};
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
function normalizeString(str) {
  return str.replace(/\s+/g, " ").trim();
}
// function findIdentifierFromDocument(identifier, documentText) {
//   console.log("==inside find identifier==");
//   console.log("==identifier==", identifier);
//   console.log("==documentText==", documentText.slice(0, 400));
//   const normalizedIdentifier = normalizeString(identifier);
//   const normalizedDocumentText = normalizeString(documentText);
//   console.log("==normalizedIdentifier==", normalizedIdentifier);
//   console.log("==normalizedDocumentText==", normalizedDocumentText.slice(0, 400));
//   const startIndex = normalizedDocumentText.indexOf(normalizedIdentifier);
//   // Return the documentText starting from the identifier
//   const matchLength = normalizedIdentifier.length;
//   if (startIndex !== -1) {
//     const escapedIdentifier = escapeRegExp(identifier).replace(/\s+/g, "\\s+");
//     const identifierRegex = new RegExp(escapedIdentifier);
//     // const identifierRegex = new RegExp(identifier.replace(/\s+/g, "\\s+"));
//     const actualStartIndex = documentText.search(identifierRegex);
//     console.log("actualStartIndex:", actualStartIndex);
//     console.log("startIndex + matchLength:", startIndex, matchLength);
//     if (actualStartIndex !== -1) {
//       return documentText.substring(actualStartIndex, startIndex + matchLength).slice(0, 200);
//     }
//   } else {
//     return null;
//   }
// }
function findIdentifierFromDocument(identifier, documentText) {
  console.log("==inside find identifier==");
  console.log("==identifier==", identifier);
  // documentText = documentText.length > 400 ? documentText.slice(0, 400) : documentText;
  // console.log("==documentText==", documentText.slice(0, 400));
  console.log("==documentText==", documentText);
  const normalizedIdentifier = normalizeString(identifier);
  const normalizedDocumentText = normalizeString(documentText);

  console.log("==normalizedIdentifier==", normalizedIdentifier);
  console.log("==normalizedDocumentText==", normalizedDocumentText);

  const startIndex = normalizedDocumentText.indexOf(normalizedIdentifier);

  if (startIndex !== -1) {
    const escapedIdentifier = escapeRegExp(identifier).replace(/\s+/g, "\\s+");
    const identifierRegex = new RegExp(escapedIdentifier);
    const match = documentText.match(identifierRegex);
    if (match) {
      console.log("actualStartIndex:", match.index);
      console.log("matchedText:", match[0]);
      return match[0];
    }
  } else {
    return null;
  }
}
export const moveChangesWithTrackOn = async (data: IMoveChangesWithTrackOn) => {
  let result;
  try {
    // await Word.run(async (context) => {
    //   const document = context.document;
    //   document.load(MS_WORD_PARAMS.CHANGE_TRACKING_MODE);
    //   // Word.ChangeTrackingMode.trackMineOnly | Word.ChangeTrackingMode.off | Word.ChangeTrackingMode.trackAll;
    //   document.changeTrackingMode = Word.ChangeTrackingMode.trackAll;
    //   await context.sync();
    // });
    console.log("platform:", Office.context.platform.toString());
    if (Office.context.platform.toString() === PLAT_FORM.WEB) {
      result = await moveChangeForWeb(data);
    } else {
      console.log("inside else");
      result = await moveChangesForDesktop(data);
    }
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("moveChangesWithTrackOn", error);
  }
  return result;
};

export const moveChangesForDesktop = async (data) => {
  console.log("==data==", data);
  let result;
  // let identifier = data.locateIdentifier.slice(0, 200).trim();
  let identifier = data.locateIdentifier;
  // identifier = identifier.length > 100 ? identifier.slice(0, 100).trim() : identifier;

  return await Word.run(async (context) => {
    const body = context.document.body;
    context.load(body, MS_WORD_PARAMS.TEXT);
    await context.sync();
    const locateIdentifierProper = findIdentifierFromDocument(identifier, body.text);
    console.log("locateIdentifierProper:", locateIdentifierProper);
    // const searchResults = body.search(locateIdentifierProper, {
    const searchResults = body.search(locateIdentifierProper.slice(0, 200).trim(), {
      matchCase: true,
    });
    console.log("searchResults:", searchResults);
    context.load(searchResults, MS_WORD_PARAMS.ITEMS);
    await context.sync();
    if (searchResults.items.length > 0) {
      const rangeOfLocatedIdentifier = searchResults.items[0];
      const paragraph = rangeOfLocatedIdentifier.paragraphs;
      console.log("paragraph:", paragraph);
      context.load(paragraph, MS_WORD_PARAMS.TEXT);
      paragraph.load(MS_WORD_PARAMS.ITEMS);
      await context.sync();
      const paragraphText = paragraph.items[0].text
        ?.replace(new RegExp(`^${data.bulletIndex}`), "")
        .replace(/\s+/g, " ")
        .trim();
      console.log("paragraphText:", paragraphText);
      const foundParagraph = paragraphText.includes(
        data.locateIdentifier.replace(/\s+/g, " ").trim().replace(/\.$/, "")
      );
      console.log("foundParagraph:", foundParagraph);
      if (foundParagraph) {
        const differences = await findDifferences(
          paragraph.items[0].text
            ?.replace(new RegExp(`^${data.bulletIndex}`), "")
            // .replace(/\s+/g, " ")
            .trim(),
          data.textToBeReplaced,
          10
        );
        console.log("differences:", differences);
        const wordsToReplaceArray = [];
        for (const difference of differences) {
          const searchResult = paragraph?.items[0]?.search(difference?.context?.replace(/\#\#/g, ""), {
            matchCase: true,
          });
          context.load(searchResult, MS_WORD_PARAMS.ITEMS);
          await context.sync();
          console.log("searchResult.items[0]=", searchResult.items[0]);
          if (searchResult.items.length > 0) {
            if (difference.original) {
              const wordsToReplaceRange = searchResult.items[0].search(difference.original, {
                matchCase: true,
              });
              context.load(wordsToReplaceRange, MS_WORD_PARAMS.ITEMS);
              // await context.sync();

              wordsToReplaceArray.push({
                key: "REPLACE",
                text: difference.replacedBy,
                range: wordsToReplaceRange,
              });
            } else {
              if (!difference.context.includes("##")) {
                const wordsToReplaceRange = searchResult.items[0].search(difference.context, {
                  matchCase: true,
                });
                context.load(wordsToReplaceRange, MS_WORD_PARAMS.ITEMS);
                wordsToReplaceArray.push({
                  key: "AFTER",
                  text: " " + difference.replacedBy,
                  range: wordsToReplaceRange,
                });
              } else {
                const markedSentence = difference?.context?.match(/\#\#(.*?)\#\#/)?.[1] ?? "";
                console.log(markedSentence);
                const markedSentenceRange = searchResult.items[0].search(markedSentence, {
                  matchCase: true,
                });
                context.load(markedSentenceRange, MS_WORD_PARAMS.ITEMS);
                wordsToReplaceArray.push({
                  key: "ADD",
                  text: difference.replacedBy + " ",
                  range: markedSentenceRange,
                });
              }
            }
            context.load(searchResult, MS_WORD_PARAMS.ITEMS);
          }
        }
        await context.sync();
        for (const word of wordsToReplaceArray) {
          console.log("word:", word.range.items[0].text);

          if (word.key === "REPLACE") {
            console.log("Before clearing:", word.range.items[0].text);
            word.range.items[0].insertText(word.text, Word.InsertLocation.replace);
            console.log("After clearing:", word.range.items[0].text);
          } else if (word.key === "AFTER") {
            word.range.items[0].insertText(word.text, Word.InsertLocation.end);
          } else {
            word.range.items[0].insertText(word.text, Word.InsertLocation.start);
          }

          context.load(word.range, MS_WORD_PARAMS.ITEMS);
          await context.sync();
        }

        paragraph.load(MS_WORD_PARAMS.ITEMS);
        await context.sync();
        console.log("Final==", paragraph.items[0].text);
        result = true;
      }
    }
    return result;
  });
};

// export const moveChangesForDesktop = async (data) => {
//   console.log(data, "checking data for moveChanges");
//   let result;
//   let identifier = data.locateIdentifier;

//   console.log("DATA====", data);

//   await Word.run(async (context) => {
//     const body = context.document.body;
//     context.load(body, MS_WORD_PARAMS.TEXT); // Load body text
//     await context.sync(); // Sync to load the document body text

//     // Truncate the locateIdentifier to avoid 'SearchStringInvalidOrTooLong' error
//     const locateIdentifierProper = findIdentifierFromDocument(identifier, body.text).slice(0, 200).trim();
//     console.log("locateIdentifierProper:", locateIdentifierProper);

//     // Perform search
//     const searchResults = body.search(locateIdentifierProper, {
//       matchCase: true,
//     });

//     context.load(searchResults, MS_WORD_PARAMS.ITEMS); // Load the search result items
//     await context.sync(); // Sync to load the search results

//     if (searchResults.items.length > 0) {
//       const rangeOfLocatedIdentifier = searchResults.items[0]; // Get the first search result
//       const paragraph = rangeOfLocatedIdentifier.paragraphs;

//       context.load(paragraph, MS_WORD_PARAMS.TEXT); // Load the text of the paragraph
//       context.load(paragraph, MS_WORD_PARAMS.ITEMS); // Load the items of the paragraph collection
//       await context.sync(); // Sync to ensure the paragraph and its text are loaded

//       const paragraphText = paragraph.items[0].text
//         ?.replace(new RegExp(`^${data.bulletIndex}`), "")
//         .replace(/\s+/g, " ")
//         .trim();

//       console.log("paragraphText:", paragraphText);

//       // Check if the paragraph contains the identifier
//       const foundParagraph = paragraphText.includes(
//         data.locateIdentifier.replace(/\s+/g, " ").trim().replace(/\.$/, "")
//       );
//       console.log("foundParagraph:", foundParagraph);

//       if (foundParagraph) {
//         // Find differences between the paragraph text and the new text to be inserted
//         const differences = await findDifferences(
//           paragraph.items[0].text
//             ?.replace(new RegExp(`^${data.bulletIndex}`), "")
//             .replace(/\s+/g, " ")
//             .trim(),
//           data.textToBeReplaced,
//           6
//         );
//         console.log("differences:", differences);

//         const wordsToReplaceArray = [];

//         for (const difference of differences) {
//           // Search for the difference context in the paragraph text
//           const searchResult = paragraph?.items[0]?.search(difference?.context?.replace(/\#\#/g, ""), {
//             matchCase: true,
//           });

//           context.load(searchResult, MS_WORD_PARAMS.ITEMS); // Load the search result items
//           await context.sync(); // Sync to ensure the search result items are available

//           if (searchResult.items.length > 0) {
//             if (difference.original) {
//               const wordsToReplaceRange = searchResult.items[0].search(difference.original, {
//                 matchCase: true,
//               });

//               context.load(wordsToReplaceRange, MS_WORD_PARAMS.ITEMS); // Load the items in the range
//               await context.sync(); // Sync to ensure the range items are loaded

//               wordsToReplaceArray.push({
//                 key: "REPLACE",
//                 text: difference.replacedBy,
//                 range: wordsToReplaceRange,
//               });
//             } else {
//               if (!difference.context.includes("##")) {
//                 const wordsToReplaceRange = searchResult.items[0].search(difference.context, {
//                   matchCase: true,
//                 });

//                 context.load(wordsToReplaceRange, MS_WORD_PARAMS.ITEMS); // Load the items in the range
//                 await context.sync(); // Sync to ensure the range items are loaded

//                 wordsToReplaceArray.push({
//                   key: "AFTER",
//                   text: " " + difference.replacedBy,
//                   range: wordsToReplaceRange,
//                 });
//               } else {
//                 const markedSentence = difference?.context?.match(/\#\#(.*?)\#\#/)?.[1] ?? "";
//                 console.log(markedSentence);

//                 const markedSentenceRange = searchResult.items[0].search(markedSentence, {
//                   matchCase: true,
//                 });

//                 context.load(markedSentenceRange, MS_WORD_PARAMS.ITEMS); // Load the items in the range
//                 await context.sync(); // Sync to ensure the range items are loaded

//                 wordsToReplaceArray.push({
//                   key: "ADD",
//                   text: difference.replacedBy + " ",
//                   range: markedSentenceRange,
//                 });
//               }
//             }
//           }
//         }

//         await context.sync(); // Sync again to ensure all the words to be replaced are loaded

//         // Replace the text or insert text as needed
//         for (const word of wordsToReplaceArray) {
//           if (word.key === "REPLACE") {
//             word.range.items[0].insertText(word.text, Word.InsertLocation.replace);
//           } else if (word.key === "AFTER") {
//             word.range.items[0].insertText(word.text, Word.InsertLocation.end);
//           } else {
//             word.range.items[0].insertText(word.text, Word.InsertLocation.start);
//           }

//           context.load(word.range, MS_WORD_PARAMS.ITEMS); // Load the modified ranges
//           await context.sync(); // Sync to ensure the text replacement/insertion is applied
//         }

//         paragraph.load(MS_WORD_PARAMS.ITEMS); // Reload the paragraph items after modification
//         await context.sync(); // Sync to get the final text

//         console.log("Final==", paragraph.items[0].text);
//         result = true;
//       }
//     }

//     return result;
//   });
// };

export const moveChangeForWeb = async (data) => {
  let result;
  try {
    let identifier = data.locateIdentifier.slice(0, 200).trim();
    await Word.run(async (context) => {
      const body = context.document.body;
      context.load(body, MS_WORD_PARAMS.TEXT);
      await context.sync();
      const locateIdentifierProper = findIdentifierFromDocument(identifier, body.text);
      console.log("locateIdentifierProper:", locateIdentifierProper);
      const searchResults = body.search(locateIdentifierProper, {
        matchCase: true,
      });
      console.log("searchResults:", searchResults);
      context.load(searchResults, MS_WORD_PARAMS.ITEMS);
      await context.sync();
      if (searchResults.items.length > 0) {
        const rangeOfLocatedIdentifier = searchResults.items[0];
        const paragraph = rangeOfLocatedIdentifier.paragraphs;
        console.log("paragraph:", paragraph);
        context.load(paragraph, MS_WORD_PARAMS.TEXT);
        paragraph.load(MS_WORD_PARAMS.ITEMS);
        await context.sync();
        const paragraphText = paragraph.items[0].text?.replace(data.bulletIndex, "").replace(/\s+/g, " ").trim();
        console.log("paragraphText:", paragraphText);
        const foundParagraph = paragraphText.includes(
          data.locateIdentifier.replace(/\s+/g, " ").trim().replace(/\.$/, "")
        );
        console.log("foundParagraph:", foundParagraph);

        if (foundParagraph) {
          let differences = await findDifferences(
            paragraph.items[0].text.replace(data.bulletIndex, "").trim(),
            data.textToBeReplaced,
            6
          );
          console.log("differences:", differences);
          const originalLength = differences.length;
          for (let i = 0; i < originalLength; i++) {
            const difference = differences[0];
            const searchResult = paragraph?.items[0]?.search(difference?.context?.replace(/\#\#/g, ""), {
              matchCase: true,
            });

            context.load(searchResult, MS_WORD_PARAMS.ITEMS);
            await context.sync();
            console.log("searchResult", searchResult.items[0]?.text);

            if (searchResult.items.length > 0) {
              // If original text exists, it mean we have replace that oringal with ReaplcedBy
              if (difference.original) {
                const wordsToReplaceRange = searchResult.items[0].search(difference.original, {
                  matchCase: true,
                });
                context.load(wordsToReplaceRange, MS_WORD_PARAMS.ITEMS);
                await context.sync();
                console.log("REPLACING / DELETING THE WORD", wordsToReplaceRange.items[0]);
                console.log("Before clearing:", wordsToReplaceRange.items[0].text);
                if (difference.replacedBy) {
                  console.log(`(replacing)='${wordsToReplaceRange.items[0].text}' by -> '${difference.replacedBy}'`);
                  wordsToReplaceRange.items[0].clear();
                  await context.sync();
                  console.log("After clearing:", wordsToReplaceRange.items[0].text);
                  wordsToReplaceRange.items[0].insertText(difference.replacedBy, Word.InsertLocation.replace);
                } else {
                  console.log(`(removing)=${wordsToReplaceRange.items[0].text}`);
                  wordsToReplaceRange.items[0].delete();
                  await context.sync();
                }
                wordsToReplaceRange.load(MS_WORD_PARAMS.TEXT);
                await context.sync();
              } else {
                console.log("ADDING THE WORD");
                if (!difference.context.includes("##")) {
                  console.log("ADDING AT END");

                  const wordsToReplaceRange = searchResult.items[0].search(difference.context, {
                    matchCase: true,
                  });
                  context.load(wordsToReplaceRange, MS_WORD_PARAMS.ITEMS);
                  await context.sync();
                  console.log("(adding at end)=", wordsToReplaceRange.items[0].text);
                  wordsToReplaceRange.items[0].insertText(difference.replacedBy, Word.InsertLocation.end);
                  wordsToReplaceRange.load(MS_WORD_PARAMS.TEXT);
                  await context.sync();
                } else {
                  console.log("ADDING NEW WORD");
                  const markedSentence = difference?.context?.match(/\#\#(.*?)\#\#/)?.[1] ?? "";
                  const markedSentenceRange = searchResult.items[0].search(markedSentence, {
                    matchCase: true,
                  });

                  context.load(markedSentenceRange, MS_WORD_PARAMS.ITEMS);
                  await context.sync();
                  console.log(`ADDING before '${markedSentenceRange.items[0].text}' <--- '${difference.replacedBy}' `);
                  markedSentenceRange.items[0].insertText(difference.replacedBy + " ", Word.InsertLocation.start);
                  markedSentenceRange.load(MS_WORD_PARAMS.TEXT);
                  await context.sync();
                }
              }
            }
            paragraph.load(MS_WORD_PARAMS.TEXT);
            paragraph.load(MS_WORD_PARAMS.ITEMS);
            await context.sync();
            const textAfterIteration = paragraph.items[0].getText();
            await context.sync();
            console.log(textAfterIteration.value);
            differences = await findDifferences(
              textAfterIteration.value.replace(data.bulletIndex, "").trim(),
              data.textToBeReplaced,
              6
            );
            console.log("differences after iteration:", differences.length);
            console.log(differences);
          }

          paragraph.load(MS_WORD_PARAMS.ITEMS);
          await context.sync();
          console.log("Final==", paragraph.items[0].getText());
          result = true;
        }
      }
    });
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("moveChangesWithTrackOn", error);
  }

  return result;
};
export const setTrackChanges = async (data: ISetTrackChanges) => {
  let result;
  try {
    await Word.run(async (context) => {
      const document = context.document;
      document.load(MS_WORD_PARAMS.CHANGE_TRACKING_MODE);
      // Word.ChangeTrackingMode.trackMineOnly | Word.ChangeTrackingMode.off | Word.ChangeTrackingMode.trackAll;
      document.changeTrackingMode = data.trackMode;
      await context.sync();
    });
    result = true;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("setTrackChanges", error);
  }
  return result;
};
export const getTrackedUsersList = async () => {
  let result;
  try {
    await Word.run(async (context) => {
      const body: Word.Body = context.document.body;
      const trackedChanges: Word.TrackedChangeCollection = body.getTrackedChanges();
      trackedChanges.load();
      await context.sync();
      result = [];
      for (const trackedChange of trackedChanges?.items) {
        if (!result.includes(trackedChange.author)) {
          result.push(trackedChange.author);
        }
      }
    });
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("getTrackedUsersList", error);
  }
  return result;
};
export const getNumOfTrackedChangesByUsers = async () => {
  let result;
  try {
    await Word.run(async (context) => {
      const body: Word.Body = context.document.body;
      const trackedChanges: Word.TrackedChangeCollection = body.getTrackedChanges();
      trackedChanges.load();
      await context.sync();
      result = {};
      trackedChanges?.items.forEach((trackedChange) => {
        if (result[trackedChange.author]) {
          result[trackedChange.author]++;
        } else {
          result[trackedChange.author] = 1;
        }
      });
    });
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("getUsersNumOfTrackedChanges", error);
  }
  return result;
};
export const acceptAllTrackedChanges = async () => {
  let result;
  try {
    await Word.run(async (context) => {
      const body: Word.Body = context.document.body;
      const trackedChanges: Word.TrackedChangeCollection = body.getTrackedChanges();
      let trackedChange: Word.TrackedChange = trackedChanges.getFirst();
      trackedChange.load();
      await context.sync();
      try {
        while (!trackedChange.isNullObject) {
          trackedChange = trackedChanges.getFirst();
          trackedChange.load();
          await context.sync();
          trackedChange.accept();
        }
      } catch (error) {
        if (error?.debugInfo?.code === ERRORS.ITEM_NOT_FOUND) {
          return;
        }
      }
    });
    result = true;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("acceptAllTrackedChanges", error);
  }
  return result;
};
export const acceptTrackedChangesInSelectionByUserName = async (data: IAcceptTrackedChangesInSelectionByUserName) => {
  let result;
  try {
    await Word.run(async (context) => {
      const trackedChanges: Word.TrackedChangeCollection = context?.document?.getSelection()?.getTrackedChanges();
      const trackedChange: Word.TrackedChange = trackedChanges.getFirst();
      trackedChange.load();
      await context.sync();
      if (trackedChange.author.toLowerCase() === data?.userName?.toLowerCase()) {
        trackedChange.accept();
        await context.sync();
      }
    });
    result = true;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("acceptTrackedChangesInSelectionByUserName", error);
  }
  return result;
};
export const acceptAllTheTrackedChangesByUserName = async (data: IAcceptAllTheTrackedChangesByUserName) => {
  let result;
  try {
    await Word.run(async (context) => {
      const body: Word.Body = context.document.body;
      const trackedChanges: Word.TrackedChangeCollection = body.getTrackedChanges();
      let trackedChange: Word.TrackedChange = trackedChanges.getFirst();
      trackedChange.load();
      await context.sync();
      try {
        while (!trackedChange.isNullObject) {
          if (trackedChange.author.toLowerCase() === data?.userName?.toLowerCase()) {
            trackedChange.accept();
          }
          trackedChange = trackedChanges.getFirst();
          trackedChange.load();
          await context.sync();
        }
      } catch (error) {
        if (error?.debugInfo?.code === ERRORS.ITEM_NOT_FOUND) {
          return;
        }
      }
    });
    result = true;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("acceptAllTheTrackedChangesByUserName", error);
  }
  return result;
};
export const rejectAllTrackedChanges = async () => {
  let result;
  try {
    await Word.run(async (context) => {
      const body: Word.Body = context.document.body;
      const trackedChanges: Word.TrackedChangeCollection = body.getTrackedChanges();
      let trackedChange: Word.TrackedChange = trackedChanges.getFirst();
      trackedChange.load();
      await context.sync();
      try {
        while (!trackedChange.isNullObject) {
          trackedChange = trackedChanges.getFirst();
          trackedChange.load();
          await context.sync();
          trackedChange.reject();
        }
      } catch (error) {
        if (error?.debugInfo?.code === ERRORS.ITEM_NOT_FOUND) {
          return;
        }
      }
    });
    result = true;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("rejectAllTrackedChanges", error);
  }
  return result;
};
export const rejectTrackedChangesInSelectionByUserName = async (data: IRejectTrackedChangesInSelectionByUserName) => {
  let result;
  try {
    await Word.run(async (context) => {
      const trackedChanges: Word.TrackedChangeCollection = context?.document?.getSelection()?.getTrackedChanges();
      const trackedChange: Word.TrackedChange = trackedChanges.getFirst();
      trackedChange.load();
      await context.sync();
      if (trackedChange.author.toLowerCase() === data?.userName?.toLowerCase()) {
        trackedChange.reject();
        await context.sync();
      }
    });
    result = true;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("rejectTrackedChangesInSelectionByUserName", error);
  }
  return result;
};
export const rejectAllTheTrackedChangesByUserName = async (data: IRejectAllTheTrackedChangesByUserName) => {
  let result;
  try {
    await Word.run(async (context) => {
      const body: Word.Body = context.document.body;
      const trackedChanges: Word.TrackedChangeCollection = body.getTrackedChanges();
      let trackedChange: Word.TrackedChange = trackedChanges.getFirst();
      trackedChange.load();
      await context.sync();
      try {
        while (!trackedChange.isNullObject) {
          if (trackedChange.author.toLowerCase() === data?.userName?.toLowerCase()) {
            trackedChange.reject();
          }
          trackedChange = trackedChanges.getFirst();
          trackedChange.load();
          await context.sync();
        }
      } catch (error) {
        if (error?.debugInfo?.code === ERRORS.ITEM_NOT_FOUND) {
          return;
        }
      }
    });
    result = true;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("rejectAllTheTrackedChangesByUserName", error);
  }
  return result;
};
export const getFileName = async () => {
  let documentName = "";
  try {
    Word.run((context) => {
      return context.sync().then(() => {
        // Get the Office document properties
        Office.context.document.getFilePropertiesAsync((asyncResult) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            const fileUrl = asyncResult.value.url;
            documentName = fileUrl
              .replace(/[\\/]+/g, "##")
              .split("##")
              .pop();
          } else {
            console.error("Error:", asyncResult.error.message);
          }
        });
      });
    });
  } catch (error) {
    printUtilErrorOnConsole("getFileName", error);
  }
  return documentName;
};
export const getCommentsUsersList = async () => {
  let result;
  try {
    await Word.run(async (context) => {
      const comments = context.document.body.getComments();
      comments.load();
      await context.sync();
      if (comments?.items?.length) {
        result = [];
        comments?.items?.forEach((comment) => {
          if (!result.includes(comment?.authorName)) {
            result.push(comment?.authorName);
          }
        });
      }
    });
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("getCommentsUsersList", error);
  }
  return result;
};
export const getNumOfCommentsByUsers = async () => {
  let result;
  try {
    await Word.run(async (context) => {
      const comments = context.document.body.getComments();
      comments.load();
      await context.sync();
      if (comments?.items?.length) {
        result = {};
        comments?.items?.forEach((comment) => {
          if (result[comment?.authorName]) {
            result[comment?.authorName]++;
          } else {
            result[comment?.authorName] = 1;
          }
        });
      }
    });
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("getNumOfCommentsByUsers", error);
  }
  return result;
};
// export const getPageContext = async (data: IGetPageContext) => {
//   let result = "";
//   try {
//     await Word.run(async (context) => {
//       const document = context.document;
//       const body = document.body;
//       context.load(body, MS_WORD_PARAMS.PARAGRAPHS);
//       await context.sync();

//       const paragraphs = body.paragraphs;
//       context.load(paragraphs, MS_WORD_PARAMS.ITEMS_TEXT);
//       await context.sync();

//       let pageContent = "";
//       let lineCount = 0;
//       const averageLinesPerPage = 35;
//       const startLine = (Number(data.pageNumber) - 1) * averageLinesPerPage; //30
//       const endLine = Number(data.pageNumber) * averageLinesPerPage; //60

//       for (let i = 0; i < paragraphs.items.length; i++) {
//         const paragraph = paragraphs.items[i];
//         const paragraphText = paragraph.text;
//         const lineEstimate = Math.ceil(paragraphText.length / 80);

//         if (lineCount + lineEstimate > startLine && lineCount < endLine) {
//           pageContent += paragraphText + "\n";
//         }

//         lineCount += lineEstimate;
//         if (lineCount >= endLine) {
//           break;
//         }
//       }
//       if (pageContent == "") {
//         result = "END_OF_DOCUMENT";
//       } else {
//         result = `Page_${data.pageNumber}:${pageContent}`;
//       }
//     });
//   } catch (error) {
//     printUtilErrorOnConsole("getPageContext", error);
//   }
//   return result;
// };
export const getPageContext = async (data: IGetPageContext) => {
  let result = "";
  try {
    await Word.run(async (context) => {
      const document = context.document;
      const body = document.body;
      context.load(body, MS_WORD_PARAMS.PARAGRAPHS);
      await context.sync();

      const paragraphs = body.paragraphs;
      context.load(paragraphs, MS_WORD_PARAMS.ITEMS_TEXT);
      await context.sync();

      let pageContent = "";
      let lineCount = 0;
      const averageLinesPerPage = 35;
      const startLine = (Number(data.pageNumber) - 1) * averageLinesPerPage;
      const endLine = Number(data.pageNumber) * averageLinesPerPage;

      let hasMoreContent = false;

      for (let i = 0; i < paragraphs.items.length; i++) {
        const paragraph = paragraphs.items[i];
        const paragraphText = paragraph.text;
        const lineEstimate = Math.ceil(paragraphText.length / 80);

        if (lineCount + lineEstimate > startLine && lineCount < endLine) {
          pageContent += paragraphText + "\n";
        }

        lineCount += lineEstimate;

        if (lineCount >= endLine) {
          if (i + 1 < paragraphs.items.length) {
            hasMoreContent = true;
          }
          break;
        }
      }

      if (!hasMoreContent) {
        result = `Page_${data.pageNumber}:END_OF_DOCUMENT`;
      } else {
        result = `Page_${data.pageNumber}:${pageContent}`;
      }
    });
  } catch (error) {
    printUtilErrorOnConsole("getPageContext", error);
  }
  console.log(result, "result");
  return result;
};
export const addCommentBySelection = async (data: IAddCommentBySelection) => {
  let result;
  try {
    await Word.run(async (context) => {
      const text = data?.text;
      const comment = context.document.getSelection().insertComment(text);
      comment.load();
      await context.sync();
    });
    result = true;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("addCommentBySelection", error);
  }
  return result;
};
export const resolveAllComments = async () => {
  let allCommentsResolved;
  try {
    await Word.run(async (context) => {
      const comments = context.document.body.getComments();
      comments.load();
      await context.sync();
      if (comments?.items?.length) {
        for (const comment of comments?.items) {
          comment.load("resolved");
          await context.sync();
          if (!comment.resolved) {
            comment.resolved = true;
            comment.load("resolved");
            await context.sync();
          }
        }
        allCommentsResolved = true;
      } else {
        allCommentsResolved = false;
      }
    });
  } catch (error) {
    allCommentsResolved = false;
    printUtilErrorOnConsole("resolveAllComments", error);
  }
  return allCommentsResolved;
};
export const resolveCommentInSelectionByUserName = async (data: IResolveCommentInSelectionByUserName) => {
  let result;
  // Edits the first active comment in the selected content
  try {
    await Word.run(async (context) => {
      const comments: Word.CommentCollection = context.document.getSelection().getComments();
      comments.load(MS_WORD_PARAMS.ITEMS);
      await context.sync();
      const firstActiveComment: Word.Comment = comments.items.find(
        (comment) => comment.resolved !== true && comment.authorName?.toLowerCase() === data.userName?.toLowerCase()
      );
      if (!firstActiveComment) {
        result = false;
        return;
      }
      firstActiveComment.load();
      firstActiveComment.resolved = true;
      firstActiveComment.load("resolved");
      await context.sync();
      result = true;
    });
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("resolveCommentInSelectionByUserName", error);
  }
  return result;
};
export const resolveAllCommentsByUserName = async (data: IResolveAllCommentsByUserName) => {
  let result;
  // Edits the first active comment in the selected content
  try {
    await Word.run(async (context) => {
      const comments: Word.CommentCollection = context.document.body.getComments();
      comments.load(MS_WORD_PARAMS.ITEMS);
      await context.sync();

      for (const comment of comments.items) {
        if (comment.resolved !== true && comment.authorName?.toLowerCase() === data.userName?.toLowerCase()) {
          comment.load();
          comment.resolved = true;
          comment.load("resolved");
          await context.sync();
        }
      }
      result = true;
    });
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("resolveAllCommentsByUserName", error);
  }
  return result;
};
export const rejectAllComments = async () => {
  let allCommentsRejected;
  try {
    await Word.run(async (context) => {
      const comments = context.document.body.getComments();
      comments.load();
      await context.sync();
      if (comments?.items?.length) {
        for (const comment of comments?.items) {
          comment.delete();
          await context.sync();
        }
        allCommentsRejected = true;
      } else {
        allCommentsRejected = false;
      }
    });
  } catch (error) {
    allCommentsRejected = false;
    printUtilErrorOnConsole("rejectAllComments", error);
  }
  return allCommentsRejected;
};
export const rejectAllCommentsByUserName = async (data: IRejectAllCommentsByUserName) => {
  let result;
  try {
    await Word.run(async (context) => {
      const comments: Word.CommentCollection = context.document.body.getComments();
      comments.load(MS_WORD_PARAMS.ITEMS);
      await context.sync();

      for (const comment of comments.items) {
        if (comment.resolved !== true && comment.authorName?.toLowerCase() === data.userName?.toLowerCase()) {
          comment.delete();
          await context.sync();
        }
      }
      result = true;
    });
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("rejectAllCommentsByUserName", error);
  }
  return result;
};
export const insertAfterCursor = async (data: IInsertAfterCursor) => {
  let result;
  try {
    await Word.run(async (context) => {
      const selection = context.document.getSelection();
      selection.getRange().insertText("", Word.InsertLocation.after);
      await context.sync();
      const htmlContent = markdownToHtml(data.content);
      selection.insertHtml(htmlContent, Word.InsertLocation.after);
      await context.sync();
      const replacedRange = selection.getRange();
      replacedRange.select();
      await context.sync();
    });
    result = true;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("insertAfterCursor", error);
  }
  return result;
};
export const replaceSelection = async (data: IReplaceSelection) => {
  let result;
  try {
    await Word.run(async (context) => {
      const range = context.document.getSelection();
      range.load(MS_WORD_PARAMS.TEXT);
      await context.sync();
      if (range.text) {
        const htmlContent = markdownToHtml(data.content);
        await Word.run(async (context) => {
          const range = context.document.getSelection();
          range.load(MS_WORD_PARAMS.TEXT);
          await context.sync();
          range.insertText("", Word.InsertLocation.replace);
          await context.sync();
          range.insertHtml(htmlContent, Word.InsertLocation.after);
          await context.sync();
        });
        result = true;
      } else {
        result = false;
      }
    });
  } catch (error) {
    printUtilErrorOnConsole("replaceSelection", error);
  }
  return result;
};
export const createWordFile = async () => {};
export const uploadFileToServer = async (data) => {
  console.log(data);
  try {
    // Create the HTML file from the document
    const file = await Word.run(async (context) => {
      const body = context.document.body;
      context.load(body, MS_WORD_PARAMS.TEXT);
      const html = body.getHtml();
      await context.sync();
      localStorage.setItem(LOCAL_STORAGE.DOCUMENT_CONTENT, body.text);
      // Parse the HTML content
      let parser = new DOMParser();
      let doc = parser.parseFromString(html.value, "text/html");

      // Find the <head> element
      let head = doc.head;

      // Create the <meta charset="UTF-8"> element
      let metaCharset = doc.createElement("meta");
      metaCharset.setAttribute("charset", "UTF-8");

      // Insert the <meta charset="UTF-8"> element into the <head>
      head.insertBefore(metaCharset, head.firstChild);

      // Serialize the updated HTML content
      const updatedHtml = doc.documentElement.outerHTML;

      // Convert HTML string with meta tag to UTF-8 encoded Blob
      const utf8Html = new Blob([new TextEncoder().encode(updatedHtml)], { type: "text/html;charset=utf-8" });

      // Create the file object
      return new File([utf8Html], "document.html", {
        type: "text/html;charset=utf-8",
      });
    });

    if (file) {
      // Convert file to base64
      const base64String = await fileToBase64(file);
      // Serialize file details and base64 data
      const serializedFile = {
        name: file.name,
        type: file.type,
        size: file.size,
        lastModified: file.lastModified,
        data: base64String,
      };
      return JSON.stringify(serializedFile); // Return serialized JSON
    }
    return undefined;
  } catch (error) {
    console.error("Error creating HTML file:", error);
    return undefined;
  }
};
// Function to convert File to Base64
const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(",")[1]); // Remove the data URL prefix
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
export const processReplacements = async (data: any) => {
  let result;
  try {
    await Word.run(async (context) => {
      const body = context.document.body;
      context.load(body, MS_WORD_PARAMS.TEXT);
      await context.sync();
      let i = 1;
      console.log("==replacement array==", data);

      // search and replace using identifier
      for (const item of data.list.replacementArray) {
        const { identifier, original, replacement } = item;
        console.log("%cITERATION== " + i, "color: blue; font-size: 20px; font-weight: bold;");
        const locateIdentifierProper = findIdentifierFromDocument(`${identifier}`, body.text);
        console.log("==locateIdentifierProper==", locateIdentifierProper);
        const originalProper = findIdentifierFromDocument(`${original}`, body.text);
        console.log("==originalProper==", originalProper);

        const searchResults = body.search(locateIdentifierProper, { matchCase: true });
        context.load(searchResults, MS_WORD_PARAMS.ITEMS);
        console.log("==searchResults==", searchResults);
        await context.sync();

        if (searchResults.items.length > 0) {
          // first result only
          const wordsToReplaceRange = searchResults.items[0].search(originalProper, { matchCase: true });
          context.load(wordsToReplaceRange, MS_WORD_PARAMS.ITEMS);
          await context.sync();
          if (wordsToReplaceRange.items.length > 0) {
            console.log("Replacing " + "`" + original + "`" + " with " + "`" + replacement + "`");
            wordsToReplaceRange.items[0].insertText(replacement, Word.InsertLocation.replace);
            wordsToReplaceRange.load(MS_WORD_PARAMS.TEXT);
            await context.sync();
          }
          searchResults.load(MS_WORD_PARAMS.ITEMS);
        } else {
          console.log(`Identifier "${identifier}" not found in the document.`);
        }
        body.load(MS_WORD_PARAMS.ITEMS);
        await context.sync();
        i++;
      }

      // search and replace directly
      for (const item of data.list.findAndReplaceArray) {
        const { old, new_term } = item;
        const findResults = body.search(old, { matchCase: true });
        context.load(findResults, MS_WORD_PARAMS.ITEMS);
        await context.sync();
        if (findResults.items.length > 0) {
          for (const result of findResults.items) {
            result.insertText(new_term, Word.InsertLocation.replace);
          }
          await context.sync();
        } else {
          console.log(`Term "${old}" not found in the document.`);
        }
      }
    });
    result = true;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("processReplacements", error);
  }
  return result;
};

export const getAllContent = async () => {
  let result;
  try {
    await Word.run(async (context) => {
      const body = context.document.body;
      context.load(body, MS_WORD_PARAMS.TEXT);
      await context.sync();
      // console.log("current content:", body.text);
      result = body.text;
    });
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("getAllContent", error);
  }
  return result;
};

export const getPreviousContent = () => {
  const content = localStorage.getItem(LOCAL_STORAGE.DOCUMENT_CONTENT);
  // console.log("previous content:", content);
  if (content) {
    return content;
  }
  return "";
};

export const isFileContentChanged = async () => {
  let result;
  try {
    const currentContent = await getAllContent();
    const previousContent = getPreviousContent();
    // console.log(currentContent !== previousContent, "status");
    if (currentContent !== previousContent) {
      console.log("File content has been changed.");

      // console.log("%ccurrentContent== ", "color: blue; font-size: 20px; font-weight: bold;");
      // console.log(currentContent);
      // console.log("%cpreviousContent== ", "color: blue; font-size: 20px; font-weight: bold;");
      // console.log(previousContent);
      const differences = await getDifferenceBetweenTwoTexts();
      console.log("differences:", differences);

      localStorage.setItem(LOCAL_STORAGE.DOCUMENT_CONTENT, currentContent);
      result = differences;
    } else {
      result = FILE_CHANGE_STATUS.NO_CHANGE;
    }
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("getAllContent", error);
  }
  return result;
};

export const getHtmlFileContent = async (data) => {
  console.log("PARAM", data);
  let result;
  try {
    // Create the HTML file from the document
    const file = await Word.run(async (context) => {
      const body = context.document.body;
      context.load(body, MS_WORD_PARAMS.TEXT);
      const html = body.getHtml();
      await context.sync();
      localStorage.setItem(LOCAL_STORAGE.DOCUMENT_CONTENT, body.text);
      // Parse the HTML content
      let parser = new DOMParser();
      let doc = parser.parseFromString(html.value, "text/html");

      // Find the <head> element
      let head = doc.head;

      // Create the <meta charset="UTF-8"> element
      let metaCharset = doc.createElement("meta");
      metaCharset.setAttribute("charset", "UTF-8");

      // Insert the <meta charset="UTF-8"> element into the <head>
      head.insertBefore(metaCharset, head.firstChild);

      // Serialize the updated HTML content
      const updatedHtml = doc.documentElement.outerHTML;

      // Convert HTML string with meta tag to UTF-8 encoded Blob
      const utf8Html = new Blob([new TextEncoder().encode(updatedHtml)], { type: "text/html;charset=utf-8" });

      // Create the file object
      return new File([utf8Html], "document.html", {
        type: "text/html;charset=utf-8",
      });
    });

    if (file) {
      // Convert file to base64
      const base64String = await fileToBase64(file);
      // Serialize file details and base64 data
      const serializedFile = {
        name: file.name,
        type: file.type,
        size: file.size,
        lastModified: file.lastModified,
        data: base64String,
      };
      return JSON.stringify(serializedFile); // Return serialized JSON
    }
    result = false;
    return result;
  } catch (error) {
    console.error("Error creating HTML file:", error);
    result = false;
    return result;
  }
};

export const getDifferenceBetweenTwoTexts = async () => {
  let result;
  try {
    await Word.run(async (context) => {
      const body = context.document.body;
      context.load(body, MS_WORD_PARAMS.TEXT);
      await context.sync();
      const storedContent = localStorage.getItem(LOCAL_STORAGE.DOCUMENT_CONTENT);
      const differences = await findDifferences(storedContent, body.text, 6);
      // console.log("differences:", differences);
      const finalDifferences = [];
      for (const difference of differences) {
        const result = replaceOrAddBeforeHash(difference.context, difference.replacedBy, difference.original);
        console.log(result);
        finalDifferences.push(result);
      }
      // console.log("finalDifferences:", finalDifferences);
      result = finalDifferences;
    });
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("getDifferenceBetweenTwoTexts", error);
  }
  return result;
};

function replaceOrAddBeforeHash(context, replacedBy, original = "") {
  // Find the position of '##'
  const hashIndex = context.indexOf("##");

  // Case 1: If '##' is found
  if (hashIndex !== -1) {
    let beforeHash = context.slice(0, hashIndex); // Get content before '##'

    // Case 1a: If original is provided, replace it
    if (original && beforeHash.includes(original)) {
      beforeHash = beforeHash.replace(original, replacedBy);
    }
    // Case 1b: If original is not provided, insert 'replacedBy'
    else {
      beforeHash += `${replacedBy} `;
    }

    // Concatenate with content after '##' and remove '##'
    const updatedContext = beforeHash + context.slice(hashIndex + 2);

    return {
      oldText: context.replaceAll("##", ""),
      newText: updatedContext.replaceAll("##", ""),
      replacedBy: replacedBy,
      original: original,
    };
  }

  // Case 2: If '##' is not found, append 'replacedBy' at the end of the context
  const updatedContext = context + " " + replacedBy;

  return {
    newText: updatedContext,
    oldText: context,
    replacedBy: replacedBy,
    original: original,
  };
}

export const getHtmlContent = async () => {
  let result;
  try {
    await Word.run(async (context) => {
      var body = context.document.body;
      context.load(body, MS_WORD_PARAMS.TEXT);
      const html = body.getHtml();
      await context.sync();
      result = html.value;
    });
    return result;
  } catch (error) {
    result = false;
    printUtilErrorOnConsole("getHtmlContent", error);
  }
  return result;
};
